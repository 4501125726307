// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-1-1!../node_modules/vue-loader-v16/dist/stylePostLoader.js!../node_modules/postcss-loader/src/index.js??ref--6-oneOf-1-2!./assets/index.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.push([module.id, "@import url(https://use.typekit.net/rqj8ibu.css);"]);
// Module
exports.push([module.id, "/* Otherwise the default styling applies */\n\n/* Global overrides to Widgets styles */ /* import the brand fonts */\n\n", ""]);
// Exports
module.exports = exports;
